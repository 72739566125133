import * as IC from '../../assets/lib/icons';

const ui = {
  mode: {
    light: {
      icon: <IC.MdOutlineLightMode />,
    },
    dark: {
      icon: <IC.MdDarkMode />,
    },
    normal: {
      icon: <IC.VscColorMode />,
    },
    arrow: {
      icon: <IC.MdOutlineArrowDropDown />,
    },
  },
  social: {
    whatsapp: {
      icon: <IC.FaWhatsapp />,
    },
    instagram: {
      icon: <IC.FaInstagram />,
    },
    telegram: {
      icon: <IC.LiaTelegramPlane />,
    },
    email: {
      icon: <IC.GoMail />,
    },
    linkedin: {
      icon: <IC.TbWorldWww />,
    },
    calendar: {
      reg: {
        icon: <IC.FaRegCalendarAlt />,
      },
      add: {
        icon: <IC.FaRegCalendarPlus />,
      },
    },
    phone: {
      reg: {
        icon: <IC.FaPhone />,
      },
      color: {
        icon: <IC.FcPhone />,
      },
    },
    message: {
      square: {
        icon: <IC.BiMessageSquareEdit />,
      },
      rounded: {
        icon: <IC.BiSolidMessageRoundedEdit />,
      },
    },
    site: {
      icon: <IC.TbWorldWww />,
    },
  },
  action: {
    edit: {
      icon: <IC.FiEdit />,
    },
    delete: {
      trash: {
        icon: <IC.BsTrash3Fill />,
      },
      circle: {
        icon: <IC.MdOutlineRemoveCircleOutline />,
      },
    },
    add: {
      color: {
        icon: <IC.FcPlus />,
      },
      reg: {
        icon: <IC.RiMenuAddLine />,
      },
      circle: {
        icon: <IC.MdAddCircleOutline />,
      },
    },
    expand: {
      icon: <IC.MdExpand />,
    },
    download: {
      icon: <IC.FcDownload />,
    },
    upload: {
      color: {
        icon: <IC.FcUpload />,
      },
      reg: {
        icon: <IC.MdOutlineUploadFile />,
      },
    },
    magnifier: {
      icon: <IC.CgSearchLoading />,
    },
    start: {
      icon: <IC.VscDebugStart />,
    },
    search: {
      icon: <IC.MdOutlineManageSearch />,
    },
    back: {
      icon: <IC.RiArrowGoBackFill />,
    },
    save: {
      icon: <IC.BiSolidSave />,
    },
    update: {
      icon: <IC.GrUpdate />,
    },
    close: {
      icon: <IC.IoCloseSharp />,
    },
  },
  folder: {
    open: {
      icon: <IC.FcOpenedFolder />,
    },
  },
  file: {
    pdf: {
      icon: <IC.PiFilePdfFill />,
    },
    image: {
      icon: <IC.PiImageFill />,
    },
    other: {
      icon: <IC.FaRegFile />,
    },
    attach: {
      icon: <IC.MdOutlineAttachFile />,
    },
  },
  status: {
    waiting: {
      icon: <IC.GiSandsOfTime />,
    },
    done: {
      icon: <IC.IoCheckmarkDoneOutline />,
    },
  },
  user: {
    reg: {
      icon: <IC.AiOutlineUser />,
    },
    add: {
      icon: <IC.AiOutlineUserAdd />,
    },
    delete: {
      icon: <IC.AiOutlineUserDelete />,
    },
    single: {
      icon: <IC.LiaUser />,
    },
    multi: {
      icon: <IC.LiaUserFriendsSolid />,
    },
    lock: {
      icon: <IC.FaUserLock />,
    },
  },
  etapas: {
    simulacao: {
      icon: <IC.FcBullish />,
    },
    documentacao_comprador: {
      icon: <IC.FcRules />,
    },
    aprovacao_credito: {
      icon: <IC.FcMoneyTransfer />,
    },
    documentacao_vendedor: {
      icon: <IC.FcRules />,
    },
    documentacao_imovel: {
      icon: <IC.FcHome />,
    },
    avaliacao_engenharia: {
      icon: <IC.FcEngineering />,
    },
    confirmacao_valores: {
      icon: <IC.FcCurrencyExchange />,
    },
    assinatura_formularios: {
      icon: <IC.FcSurvey />,
    },
    analise_juridica: {
      icon: <IC.FcLibrary />,
    },
    assinatura_contrato: {
      icon: <IC.FcPrint />,
    },
    prefeitura: {
      icon: <IC.FcDepartment />,
    },
    cartorio: {
      icon: <IC.FcDiploma2 />,
    },
    finalizado: {
      icon: <IC.FcApproval />,
    },
    mindmap: {
      icon: <IC.FcMindMap />,
    },
  },

  misc: {
    cross: {
      icon: <IC.RxCross2 />,
    },
    check: {
      color: {
        icon: <IC.FaCircleCheck />,
      },
      circle: {
        icon: <IC.FcCheckmark />,
      },
    },
    circle: {
      icon: <IC.FaCircle />,
    },
    clock: {
      icon: <IC.FcClock />,
    },
    config: {
      icon: <IC.IoSettingsOutline />,
    },
    edit: {
      icon: <IC.FiEdit />,
    },
    add_database: {
      icon: <IC.FcAddDatabase />,
    },
    tasks: {
      icon: <IC.GoTasklist />,
    },
    star: {
      icon: <IC.FaRegStar />,
    },
    starlight: {
      icon: <IC.TbStarFilled />,
    },
    house: {
      icon: <IC.FaHouseChimney />,
    },
    money: {
      icon: <IC.BsCashCoin />,
    },
    menu: {
      icon: <IC.MdMenu />,
    },
    logout: {
      icon: <IC.AiOutlineLogout />,
    },
    products: {
      icon: <IC.AiOutlineProduct />,
    },
    dash: {
      icon: <IC.AiOutlineDashboard />,
    },
    app: {
      icon: <IC.AiOutlineAppstore />,
    },
    adjust: {
      icon: <IC.HiMiniAdjustmentsHorizontal />,
    },
    registered: {
      icon: <IC.BiRegistered />,
    },
    copyright: {
      icon: <IC.MdCopyright />,
    },
    lock: {
      icon: <IC.FaLock />,
    },
    eye: {
      on: {
        icon: <IC.FaRegEye />,
      },
      off: {
        icon: <IC.IoEyeOffOutline />,
      },
    },
    bell: {
      outline: {
        icon: <IC.IoIosNotificationsOutline />,
      },
      on: {
        icon: <IC.IoNotificationsSharp />,
      },
      off: {
        icon: <IC.IoNotificationsOffSharp />,
      },
    },
  },
  letter: {
    f: {
      icon: <IC.TbLetterF />,
    },
    s: {
      icon: <IC.TbLetterS />,
    },
  },
  money: {
    reg: {
      icon: <IC.PiCurrencyCircleDollarFill />,
    },
    color: {
      icon: <IC.BsCashCoin />,
    },
    payment: {
      icon: <IC.FcMoneyTransfer />,
    },
    invoice: {
      icon: <IC.TbFileInvoice />,
    },
    subscriber: {
      icon: <IC.LiaUsersCogSolid />,
    },
  },
};

export { IC, ui };

import { useContext } from 'react';
import PackageContext from '../context/PackageContext';

export default function Wrapper({
  conf = {},
  text = '',
  clsnmText = '',
  textHeader = '',
  clsnmTextHeader = '',
  seoInclude = true,
  seoObject = {},
}) {
  const { theme, SEO } = useContext(PackageContext);

  return (
    <div className='wrapper text-center'>
      { seoInclude && (
        <SEO
          title={seoObject.title || conf.meta.title}
          description={seoObject.description || conf.meta.description}
          name={seoObject.name || conf.meta.author}
          type={seoObject.type || 'website'} 
        />
      )}
      <a
        href='/'
        rel='noreferrer'
        target='_blank'
        className='logoHeader'
      >
        <img
          src={conf.images[theme].logo}
          className={`homeLogo mb-5`}
          alt={conf.tenant}
        />
      </a>
      <h2
        className={`fs18 fw600 ffSoft ${clsnmTextHeader}`}
      >
        {textHeader}
      </h2>
      <div className={`mt-1 fs16 ${clsnmText}`}>{text}</div>
    </div>
  );
}

const base = {
  sim: '/simulacoes',
  cidades: '/cidades',
  operacoes: '/operacoes',
  tenants: '/tenants',
  lead: '/lead',
};

export const lead = {
  create: `${base.lead}/c`,
};

const baseBWAPI = {
  mail: '/habita/mail',
  telegram: '/habita/telegram',
  wookie: '/habita/wookie',
}

export const cidades = {
  create: `${base.cidades}/c`,
  delete: `${base.cidades}/d`,
  read: `${base.cidades}/r`,
  update: {},
};

export const operacoes = {
  create: `${base.operacoes}/c`,
  delete: `${base.operacoes}/d`,
  read: `${base.operacoes}/r`,
  update: {},
};

export const sim = {
  create: `${base.sim}/c`,
  delete: `${base.sim}/d`,
  read: `${base.sim}/r`,
  update: {
    anotacoes: `${base.sim}/u/anotacoes`,
    active: `${base.sim}/u/active`,
  },
  mail: `${base.sim}/mail`,
  find: `${base.sim}/find`,
  all: `${base.sim}/all`,
};

export const simBWAPI = {
  mail: `${baseBWAPI.mail}/simulacao`,
};

export const telegramAPI = {
  notify_admin: `${baseBWAPI.telegram}/api/notify_admin`,
};

export const tenants = {
  logo_link: `${base.tenants}/logo_link`,
};

import axios from 'axios';
import CONF from '../config';

const { brand } = CONF;

export const defaults = {
  headers: {
    Authorization: '',
  },
};

export async function post(endpoint, body) {
  const api = axios.create({
    baseURL: CONF.ORA,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  response = await api.post(`${endpoint}${CONF.slash}`, body);
  return response;
}

export async function postAPI(endpoint, body) {
  const api = axios.create({
    baseURL: CONF.BWAPI,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;
  body.brand = brand;
  response = await api.post(`${endpoint}${CONF.slash}`, body);
  return response;
}

export async function get(endpoint) {
  const api = axios.create({
    baseURL: CONF.ORA,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  let response;

  const token = sessionStorage.getItem('@App:token');
  if (token) {
    api.defaults.headers.Authorization = token;
  }
  response = await api.get(`${endpoint}${CONF.slash}`);
  return response;
}

import { useContext } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { IconsUI, ui } from './icons';
import  PackageContext from '../context/PackageContext';
import '../assets/css/components/theme.css';

export default function ThemeSwitch({ drop = false }) {
  const { theme, changeTheme, systemTheme } = useContext(PackageContext);

  const themeLine = () => {
    return (
      <div className='theme'>
        <IconsUI
          info={ui.mode.light}
          clsnm={`${theme === 'light' ? 'themeActive' : ''} m-1`}
          id='light'
          tooltip='Tema Claro'
          click={() => changeTheme('light')}
        />
        <IconsUI
          info={ui.mode.dark}
          clsnm={`${theme === 'dark' ? 'themeActive' : ''} m-1`}
          id='dark'
          tooltip='Tema Escuro'
          click={() => changeTheme('dark')}
        />
        <IconsUI
          info={ui.mode.normal}
          clsnm={` m-1`}
          id='normal'
          tooltip='Tema Padrão'
          click={() => changeTheme(systemTheme)}
        />
      </div>
    );
  };

  const themeSwitch = () => {
    return (
      <div className='ms-1 divTheme'>
        <Dropdown>
          <Dropdown.Toggle
            variant=''
            id='dropdown-theme'
            aria-label='dropdown-theme'
          >
            <IconsUI
              info={ui.mode[theme]}
              clsnm='m-1'
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>{themeLine()}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  if (drop) return themeSwitch();

  return themeLine();
}

const getTheme = () => {
  return localStorage.getItem('theme');
};

export { getTheme };

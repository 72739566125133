import Form from 'react-bootstrap/Form';

export default function FormInvalidFeedback({targetId}) {
  return (
    <Form.Control.Feedback
      type='invalid'
      className='customFormFeedback'
      id={`fdbk_${targetId}`}
    ></Form.Control.Feedback>
  );
}
